import { HistoryMessage } from "../../shared/components/assistant-modal/types";
import Copilot from "./components/copilot";
import useCopilotViaWs from "./hooks/useCopilotViaWs";

function CopilotContainer() {
  const { copilotMessages: messages, sendCopilotMessage: sendMessage, isReady } = useCopilotViaWs();

  const handleSendMessage = (message: string) => {
    const history: HistoryMessage[] = messages.map((msg) => ({
      role: msg.type == "user" ? "user" : "assistant",
      content: msg.text,
    }));
    sendMessage(message, history);
  };

  return <Copilot disabled={!isReady} messages={messages} onMessageSend={handleSendMessage} />;
}

export default CopilotContainer;
