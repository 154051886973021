import api from "../../../api/v1";
import { AssistantSourceResponse, HistoryMessage } from "../types";

export default function askQuestionWithSources(
  question: string,
  teamId: number,
  history: HistoryMessage[],
  eurlex = false
): Promise<AssistantSourceResponse> {
  return api
    .post<AssistantSourceResponse>("/assistant/response-sources/", {
      question,
      eurlex,
      team: teamId,
      history,
    })
    .then((response) => response.data);
}
